@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  color: #050621;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
}

/* div {
  border: solid 1px red;
} */

.containerHero {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.txt {
  display: block;
}

section {
  padding-top: 144px;
  padding-bottom: 144px;
}

.recykling {
  background: url("../../src/img/bg-recykling.jpg");
}

.points {
  background: url("../../src/img/bg-dlaczego.jpg");
}

.tableOne {
  text-align: center;
}

.tableBottom {
  font-weight: bold;
}

nav {
  transition: background-color 0.3s, padding 0.3s;
}

nav.scrolled {
  /* background: linear-gradient(
      to bottom,
      rgba(218, 239, 180, 1) 20%,
      rgba(218, 239, 180, 0) 100%
  ); */
  background: rgba(218, 239, 180, 0.5);
  backdrop-filter: blur(10px);
  padding: 12px 0;
  transition: background-color 0.3s, padding 0.3s;
}

.polityka ul {
  list-style-type: disc;
  margin-left: 30px;
}

@media only screen and (max-width: 400px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* splash */

.left {
  background: url("../../src/img/bg-splash-left.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.right {
  background: url("../../src/img/bg-splash-right.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.polityka p {
  padding-bottom: 24px;;
}